import { useMemo, useState } from 'react';
import { Modal, ModalDialog } from '@unifyapps/ui/_components/Modal';
import { Carousel, CarouselSlots } from '@unifyapps/ui/components/Carousel';
import Stack from '@unifyapps/ui/_components/Stack';
import { Box } from '@unifyapps/ui/components/Box';
import { downloadFileInSameTab } from '../../utils/file/downloadFileInSameTab';
import { isImage, isPdf, isVideo, isCsv } from '../Media/utils';
import MediaViewerHeader from './MediaViewerHeader';
import type { MediaViewerProps, MediaType } from './types';
import ImageViewer from './Viewers/ImageViewer';
import UnsupportedFileViewer from './Viewers/UnsupportedFileViewer';
import VideoViewer from './Viewers/VideoViewer';
import PDFViewer from './Viewers/PDFViewer';
import CsvViewer from './Viewers/CsvViewer';
import { adaptPrivateUrl } from './helpers/getPreviewUrl';

const modalDialogSx = { width: '100vw', height: '100vh', padding: 0 };

const onDownload = (media: MediaType) => {
  const url = adaptPrivateUrl(media.url);

  // this is a temporary solution to download csv files,
  // need to finalize a better solution for this
  if (isCsv(media)) {
    window.open(url, '_self');
    return;
  }
  downloadFileInSameTab(url);
};

const getMediaViewer = (media: MediaType) => {
  if (isImage(media)) {
    return ImageViewer;
  }
  if (isVideo(media)) {
    return VideoViewer;
  }
  if (isPdf(media)) {
    return PDFViewer;
  }
  if (isCsv(media)) {
    return CsvViewer;
  }
  return UnsupportedFileViewer;
};

function MediaViewer(props: MediaViewerProps) {
  const { medias, initialIndex = 0, onClose } = props;
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const slideDetails = useMemo(
    () => ({ controlledSlideIndex: currentIndex, setControlledSlideIndex: setCurrentIndex }),
    [currentIndex],
  );

  return (
    <Modal onClose={onClose} open>
      <ModalDialog layout="fullscreen" sx={modalDialogSx}>
        <Stack className="h-full">
          <MediaViewerHeader
            activeIndex={currentIndex}
            media={medias[currentIndex]}
            onClose={onClose}
            onDownload={onDownload}
            totalCount={medias.length}
          />
          <Carousel className="bg-media-preview min-h-0 flex-1" slideDetails={slideDetails}>
            {medias.map((media, index) => {
              const key = `media-${index}`;
              const Viewer = getMediaViewer(media);

              return (
                <Carousel.Slot id={key} key={key} name={CarouselSlots.Slide}>
                  <Stack alignItems="center" className="size-full" justifyContent="center">
                    <Box className="flex size-full max-h-[90%] max-w-[90%]">
                      <Viewer media={media} onClose={onClose} onDownload={onDownload} />
                    </Box>
                  </Stack>
                </Carousel.Slot>
              );
            })}
          </Carousel>
        </Stack>
      </ModalDialog>
    </Modal>
  );
}

export default MediaViewer;
