import useEventCallback from '@unifyapps/hooks/useEventCallback';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import SvgXClose from '@unifyapps/icons/outline/XClose';
import SvgDownload01 from '@unifyapps/icons/outline/Download01';
import type { MouseEventHandler } from 'react';
import type { MediaType } from './types';

interface MediaViewerHeaderProps {
  media: MediaType;
  activeIndex: number;
  totalCount: number;
  onDownload: (media: MediaType) => void;
  onClose: () => void;
}

function MediaViewerHeader(props: MediaViewerHeaderProps) {
  const { media, activeIndex, totalCount, onDownload: onDownloadProp, onClose } = props;

  const onDownload = useEventCallback(() => {
    onDownloadProp(media);
  });

  const handleCancel = useEventCallback<MouseEventHandler<HTMLButtonElement>>((e) => {
    e.stopPropagation();
    onClose();
  });

  return (
    <Stack
      alignItems="center"
      className="bg-primary px-xl gap-x-xl h-[56px] shrink-0"
      data-color-scheme="dark"
      direction="row"
      justifyContent="space-between"
    >
      <Typography
        className="min-w-0 flex-1"
        color="text-primary"
        maxNumberOfLines={1}
        variant="text-md"
        weight="semi-bold"
      >
        {media.caption}
      </Typography>
      <Stack className="gap-x-sm text-primary text-xs tabular-nums" direction="row">
        <span>{activeIndex + 1}</span>
        <span>of</span>
        <span>{totalCount}</span>
      </Stack>
      <Stack className="gap-x-md flex-1" direction="row" justifyContent="end">
        <IconButton
          Icon={SvgDownload01}
          color="neutral"
          onClick={onDownload}
          size="md"
          variant="soft"
        />
        <IconButton
          Icon={SvgXClose}
          color="neutral"
          onClick={handleCancel}
          size="md"
          variant="soft"
        />
      </Stack>
    </Stack>
  );
}

export default MediaViewerHeader;
