import React, { forwardRef, useCallback } from 'react';
import toArray from '@uppy/utils/lib/toArray';
import match from 'mime-match';
import { useSnackbar } from '@unifyapps/ui/components/Snackbar';
import { useTranslation } from '@unifyapps/i18n/client';
import type { Uppy, UppyFileMeta } from '../../hooks/useUppy';
import getFileNameAndExtension from './utils/getFileNameAndExtension';
import { ACCEPT_ALL } from './const';
import HtmlFileInput from './HtmlFileInput';

interface FileInputProps {
  uppy: Uppy | null;
  accept?: string;
  multiple?: boolean | null;
  disabled?: boolean;
  fileMeta?: UppyFileMeta;
  maxNumberOfFiles?: number;
  maxFileSizeInMB?: number;
  referenceId?: string;
}

const getRelativePath = (fileMeta: FileInputProps['fileMeta'], file: File) => {
  if (fileMeta && 'path' in fileMeta && fileMeta.path) {
    return fileMeta.path;
  }

  if ('relativePath' in file) {
    return file.relativePath;
  }

  return '';
};

export const addFiles = (
  files: File[],
  source: string,
  uppy: FileInputProps['uppy'],
  fileMeta: FileInputProps['fileMeta'],
  referenceId?: string,
) => {
  const descriptors = files.map((file) => {
    const fileName = file.name.replaceAll(' ', '_');
    return {
      source,
      name: fileName,
      type: file.type,
      data: file,
      meta: {
        ...fileMeta,
        // also user to upload same file to be in different folder. fileId should be different for different path
        // https://github.com/transloadit/uppy/blob/6f6686177ab716e5b3ba688a9b66809ff08518b5/packages/%40uppy/utils/src/generateFileID.js#L21
        relativePath: getRelativePath(fileMeta, file),
        referenceId: referenceId ?? '',
      },
    };
  });

  try {
    // @ts-expect-error - uppy is not null
    uppy.addFiles(descriptors);
  } catch (err) {
    uppy?.log(err as string);
  }
};

export const isFileTypeAllowed = (accept: string, file: File) => {
  if (accept === ACCEPT_ALL) return true;

  // ref https://github.com/transloadit/uppy/blob/7599f3abcbd8ff4602be465af5667c23345c9556/packages/%40uppy/core/src/Restricter.js#L44
  const hasMatch = accept.split(',').some((_type) => {
    const type = _type.trim();

    if (type.includes('/')) {
      if (!file.type) return false;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call -- safe to call replace
      return match(file.type.replace(/;.*?$/, ''), type);
    }
    const { extension } = getFileNameAndExtension(file.name);
    if (type.startsWith('.') && extension) {
      return extension.toLowerCase() === type.slice(1).toLowerCase();
    }

    return false;
  });
  return Boolean(hasMatch);
};

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const {
    disabled,
    multiple = false,
    accept,
    uppy,
    fileMeta,
    maxFileSizeInMB,
    maxNumberOfFiles,
    referenceId,
  } = props;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation('common');

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let files = toArray(event.target.files) as File[];
      if (accept) {
        // window os allow selecting other files irrespective of accept value
        files = files.filter((file) => isFileTypeAllowed(accept, file));
      }

      const uppyFiles = uppy?.getFiles() || [];
      const filesLength = files.length + uppyFiles.length;

      if (maxNumberOfFiles && filesLength > maxNumberOfFiles) {
        showSnackbar({
          title: t('common:Uppy.MaxNumberOfFiles', {
            max: maxNumberOfFiles,
          }),
        });
        return false;
      }

      const filteredFiles = files.filter((file) => {
        if (maxFileSizeInMB && file.size >= maxFileSizeInMB * 1024 * 1024) {
          showSnackbar({
            title: t('common:Uppy.FileSizeExceeds', {
              size: maxFileSizeInMB,
            }),
          });
          return false;
        }
        return true;
      });

      addFiles(filteredFiles, 'FileInput', uppy, fileMeta, referenceId);
      // We clear the input after a file is selected, because otherwise
      // change event is not fired in Chrome and Safari when a file
      // with the same name is selected.
      // ___Why not use value="" on <input/> instead?
      //    Because if we use that method of clearing the input,
      //    Chrome will not trigger change if we drop the same file twice (Issue #768).
      // @ts-expect-error -- event target value is readonly
      event.target.value = null;
    },
    [accept, uppy, maxNumberOfFiles, fileMeta, showSnackbar, t, maxFileSizeInMB, referenceId],
  );

  const onFileSelectErrorHandler = useCallback(() => {
    showSnackbar({
      color: 'error',
      title: `Selected file type not allowed, allowed file types are ${accept}`,
    });
  }, [showSnackbar, accept]);

  return (
    <HtmlFileInput
      accept={accept}
      disabled={disabled}
      hidden
      multiple={Boolean(multiple)}
      onChange={handleInputChange}
      onFileSelectError={onFileSelectErrorHandler}
      ref={ref}
    />
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;
