import React, { useTransition, Children, useCallback, useEffect, useState } from 'react';
import type { PropsWithChildren, ReactElement, SyntheticEvent } from 'react';
import type { KeenSliderPlugin } from 'keen-slider/react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import _reduce from 'lodash/reduce';
import { clsx } from 'clsx';
import _isEmpty from 'lodash/isEmpty';
import SvgChevronRight from '@unifyapps/icons/outline/ChevronRight';
import SvgChevronLeft from '@unifyapps/icons/outline/ChevronLeft';
import _isUndefined from 'lodash/isUndefined';
import { Box } from '../Box';
import { useCarouselAutoplay } from './hooks/useCarouselAutoplay';

export const enum CarouselSlots {
  Slide = 'SLIDE',
}

export enum CarouselVariant {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

type SlotProps = { name: CarouselSlots; id: string; children: ReactElement };

function Slot(props: PropsWithChildren<SlotProps>): null {
  return null;
}

//https://github.com/rcbyr/keen-slider/issues/203
const ResizePlugin: KeenSliderPlugin = (slider) => {
  const observer = new ResizeObserver(function updateSlider() {
    slider.update();
  });

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

function Carousel({
  children,
  className,
  style,
  enableLoop = false,
  autoPlay,
  slideDetails,
  viewDetails = {
    perView: 1,
    gap: 0,
  },
  variant = CarouselVariant.DESKTOP,
  ...rest
}: {
  children: ReactElement[] | ReactElement;
  className?: string;
  style?: React.CSSProperties;
  enableLoop?: boolean;
  autoPlay?: {
    enabled: boolean;
    duration?: number;
  };
  viewDetails?: {
    perView?: number;
    gap?: number;
  };
  variant?: CarouselVariant;
  slideDetails?: {
    controlledSlideIndex: number;
    setControlledSlideIndex: (index: number) => void;
  };
} & React.HTMLAttributes<HTMLDivElement>) {
  const { controlledSlideIndex, setControlledSlideIndex } = slideDetails ?? {};

  const [_, startTransition] = useTransition();
  const [localCurrentSlideIndex, setLocalCurrentSlideIndex] = useState<number>(0);
  const [loaded, setLoaded] = useState(false);

  const childrenArr = Children.toArray(children);

  const slidesSlot = _reduce(
    childrenArr,
    (acc, child: ReactElement<SlotProps>) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- we need to check if the child is not undefined
      if (child.props.name === CarouselSlots.Slide) {
        acc.push(child);
      }

      return acc;
    },
    [] as ReactElement<SlotProps>[],
  );

  useEffect(() => {
    if (!_isUndefined(controlledSlideIndex) && localCurrentSlideIndex !== controlledSlideIndex) {
      setLocalCurrentSlideIndex(controlledSlideIndex);
    }
  }, [localCurrentSlideIndex, controlledSlideIndex, slideDetails]);

  const slidesPerView = Math.min(viewDetails.perView ?? 1, slidesSlot.length);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slides: {
        perView: slidesPerView,
        spacing: viewDetails.gap,
      },
      slideChanged(slider) {
        setLocalCurrentSlideIndex(slider.track.details.rel);
        if (setControlledSlideIndex) {
          startTransition(() => {
            setControlledSlideIndex(slider.track.details.rel);
          });
        }
      },
      created() {
        setLoaded(true);
      },
      rubberband: false, // Disable rubberband effect
      mode: 'snap',
      loop: enableLoop || autoPlay?.enabled,
    },
    [ResizePlugin],
  );

  const { clearAndResetTimer } = useCarouselAutoplay({
    autoPlay,
    instanceRef,
  });

  const moveToPrevPage = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      instanceRef.current?.prev();
      clearAndResetTimer();
    },
    [clearAndResetTimer, instanceRef],
  );

  const moveToNextPage = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();
      instanceRef.current?.next();
      clearAndResetTimer();
    },
    [clearAndResetTimer, instanceRef],
  );

  const getMoveToPage = useCallback(
    (index: number) => () => {
      instanceRef.current?.moveToIdx(index);
      clearAndResetTimer();
    },
    [clearAndResetTimer, instanceRef],
  );

  useEffect(() => {
    if (!_isUndefined(controlledSlideIndex) && localCurrentSlideIndex !== controlledSlideIndex) {
      setLocalCurrentSlideIndex(controlledSlideIndex);
      instanceRef.current?.moveToIdx(controlledSlideIndex);
    }
  }, [localCurrentSlideIndex, controlledSlideIndex, instanceRef]);

  useEffect(() => {
    instanceRef.current?.moveToIdx(0);
  }, [autoPlay?.enabled, instanceRef]);

  if (_isEmpty(slidesSlot)) {
    return null;
  }

  const totalSlidesCount = instanceRef.current
    ? instanceRef.current.track.details.slides.length
    : 0;

  return (
    <Box className={clsx('gap-md relative flex flex-col', className)} style={style} {...rest}>
      <Box className="keen-slider h-full" ref={sliderRef}>
        {slidesSlot.map((slide, idx) => {
          return (
            <Box className="keen-slider__slide h-full" key={slide.props.id}>
              {slide.props.children}
            </Box>
          );
        })}
      </Box>
      {loaded && instanceRef.current ? (
        <>
          <Box className="absolute flex h-full items-center">
            <Box
              className={clsx(
                'ms-md p-xs bg-primary hidden size-8 cursor-pointer items-center justify-center rounded-full opacity-40 shadow-md',
                {
                  invisible: !enableLoop && !autoPlay?.enabled && localCurrentSlideIndex === 0,
                  '!flex': variant === CarouselVariant.DESKTOP,
                },
              )}
              onClick={moveToPrevPage}
              type="button"
            >
              <SvgChevronLeft className="text-fg-primary size-6" strokeWidth={3} />
            </Box>
          </Box>
          <Box className="absolute end-0 flex h-full items-center">
            <Box
              className={clsx(
                'me-md p-xs bg-primary hidden size-8 cursor-pointer items-center justify-center rounded-full opacity-40 shadow-md',
                {
                  invisible:
                    !enableLoop &&
                    !autoPlay?.enabled &&
                    localCurrentSlideIndex === instanceRef.current.track.details.slides.length - 1,
                  '!flex': variant === CarouselVariant.DESKTOP,
                },
              )}
              onClick={moveToNextPage}
              type="button"
            >
              <SvgChevronRight className="text-fg-primary size-6" strokeWidth={3} />
            </Box>
          </Box>
        </>
      ) : null}
      {loaded && instanceRef.current ? (
        <Box
          className={clsx('mb-md flex w-full justify-center', {
            '!bottom-md !absolute !mb-0': variant === CarouselVariant.DESKTOP,
            'gap-xs': variant === CarouselVariant.DESKTOP,
          })}
        >
          {[...Array(totalSlidesCount).keys()].map((idx) => {
            return (
              <button
                className={clsx(
                  'bg-alpha-black-50 mx-px cursor-pointer !rounded-full border-none opacity-40',
                  variant === CarouselVariant.DESKTOP ? 'size-md' : 'size-sm',
                  {
                    '!bg-brand-solid !opacity-100': localCurrentSlideIndex === idx,
                  },
                )}
                key={idx}
                onClick={getMoveToPage(idx)}
                type="button"
              />
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
}

Carousel.Slot = Slot;

export default Carousel;
