import type { ReactNode } from 'react';
import { memo } from 'react';
import { Box } from '@unifyapps/ui/components/Box';
import Stack from '@unifyapps/ui/_components/Stack';
import { Typography } from '@unifyapps/ui/components/Typography';

type EmptyStateProps = {
  IconNode: ReactNode;
  title: string;
  description?: string;
  primaryAction?: ReactNode;
};

function EmptyState(props: EmptyStateProps) {
  return (
    <Box className="bg-brand-secondary rounded-4xl p-sm mx-auto h-fit w-[300px] self-center">
      <Stack className="gap-xl p-xl items-center rounded-xl bg-[white]" direction="column">
        {props.IconNode}
        <Stack className="gap-y-xs items-center" direction="column">
          <Typography color="text-primary" variant="text-md" weight="semi-bold">
            {props.title}
          </Typography>
          <Typography
            className="text-center"
            color="text-tertiary"
            variant="text-sm"
            weight="regular"
          >
            {props.description}
          </Typography>
        </Stack>
        {props.primaryAction}
      </Stack>
    </Box>
  );
}

const MediaViewerEmptyState = memo(EmptyState);
export default MediaViewerEmptyState;
